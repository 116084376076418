// npm install qrcode-generator

import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"

import * as qrGenerator from 'qrcode-generator';

const ECLEVELVALUES = ['L','M','Q','H'];

const QRGenerator = ({data, correctionlevel, sizepx, percentpadding}) => {
	const ecLevel = ECLEVELVALUES.includes(correctionlevel.toUpperCase())?correctionlevel.toUpperCase():ECLEVELVALUES[1];
	//const eyeRadius = [0, 0, 0];
	const canvasRef = useRef(null);


	useEffect(()=>{
		const bgCSSColor = "#FFFFFF";
		const canvas = canvasRef.current;
		const context = canvas.getContext('2d');

		if (data.length < 1) {
			context.canvas.width = sizepx;
			context.canvas.height = sizepx;

			context.beginPath();
			context.rect(0, 0, sizepx, sizepx);
			context.fillStyle = bgCSSColor;
			context.fill();
			return;
		}


		const qrCode = qrGenerator(0, ecLevel);
		qrCode.addData(data);
		qrCode.make();

		const qrdimension = qrCode.getModuleCount();


		const marginpx = sizepx>0?Math.floor(sizepx*percentpadding/100) : Math.floor(qrdimension*percentpadding/100);
		const cellsize = sizepx>0?Math.floor((sizepx-2*marginpx)/qrdimension) : 1;

		// TODO: Improve offset/margin computations to minimize gaps
		const outsizepx = sizepx>0?sizepx:(qrdimension+2*marginpx);
		const tmpsizepx = qrdimension*cellsize + 2*marginpx;
		const sizeoffset = Math.floor((outsizepx - tmpsizepx)/2);

		context.canvas.width = outsizepx;
		context.canvas.height = outsizepx;

		context.beginPath();
		context.rect(0, 0, outsizepx, outsizepx);
		context.fillStyle = bgCSSColor;
		context.fill();

		var image = new Image();
		image.onload = function() {
			context.drawImage(image, marginpx+sizeoffset, marginpx+sizeoffset);
		};
		image.src = qrCode.createDataURL(cellsize, 0);

		//qrCode.renderTo2dContext(context, cellsize);

	}, [data, sizepx])


	const downloadImage = function(e)
	{
		if (e) {
			e.preventDefault();
		}
		window.open(canvasRef.current.toDataURL(),"_blank");
	}

	return <>
			<canvas title="Click to Download" style={{
				display: "block",
				width: "100%",
				cursor: "pointer"
			}} ref={canvasRef} onClick={downloadImage} />
	</>
}


QRGenerator.propTypes = {
	sizepx: PropTypes.number,
	percentpadding: PropTypes.number,
	correctionlevel: PropTypes.string,
	data: PropTypes.string
};

QRGenerator.defaultProps = {
	sizepx: 150,
	percentpadding: 2,
	correctionlevel: "M",
	data: ""
};


export default QRGenerator