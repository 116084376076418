
import React from "react"
import OnsbookingPage from "../../components/negosyonow/controls/onsbookingeditor"

const SupplierorderPage = ({location}) => {

	return <OnsbookingPage
				location={location}
				/>

}


export default SupplierorderPage;
